<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {email, required} from "vuelidate/lib/validators";
import {useUserStore} from "@/store/user";
import axios from "axios";
import allCountries from "@/helpers/all-countries";
import {computed} from "vue";
import {userService} from "@/helpers/user.service";
import {errorCatcher} from "@/helpers/error-catcher";
import parsePhoneNumber from 'libphonenumber-js'
import Swal from "sweetalert2";
import Enable2faModal from "@/components/profile/enable-2fa-modal.vue";
import {swalHelper} from "@/helpers/swal-helper";
import ecatCropper from "@/components/ecat-cropper.vue";
import {imageHelper} from "../../../helpers/image-helper";
import {timeUtil} from "../../../helpers/time-util";

export default {
  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('navbar.dropdown.profile'),
          active: true
        }
      ]
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.form.pictureFile);
      formData.append('extension', this.form.extension)

      try {
        const { data } = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.form.newProfilePicture = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    disable2FA() {
      swalHelper.yesOrNoCustomizable(() => {
        axios.post(`/user/2fa/disable`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(() => {
          userService.login(false).then(() => {
            Swal.fire("Sukces!", "Logowanie dwuetapowe zostało wyłączone!", "success");
            this.loadForm()
          })
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", `Logowanie dwuetapowe zostanie wyłączone`, "Wyłącz", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    async save() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!this.isPossibleNumber()) {
        return
      }

      if (this.form.pictureFile) {
        try {
          await this.upload()
        } catch (ignored) {
          return
        }
      }

      const json = JSON.stringify({
        "user": {
          "email": this.form.email,
          "firstName": this.form.firstName,
          "lastName": this.form.lastName,
          "username": this.form.username,
          "dialCode": this.form.dialCode,
          "phoneNumber": this.form.phoneNumber
        },

        "newProfilePicture": this.form.newProfilePicture
      });

      axios.post(`/user/profile`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.submitted = false;

        const data = result.data
        if (data.verificationStep === 'VERIFY_NEW_EMAIL' || data.verificationStep === 'VERIFY_NEW_PHONE_NUMBER') {
          this.openVerificationModal(data.verificationStep)
        } else {
          this.loadForm()
          this.$bvToast.toast(this.$t('profile.saved'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });
        }

      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async openVerificationModal(verificationStep) {
      this.modals.verification.verificationCode = ""
      this.modals.verification.verificationStep = verificationStep
      if (this.modals.verification.verificationStep === "VERIFY_NEW_EMAIL") {
        this.modals.verification.verificationType = "NEW_EMAIL"
      } else if (this.modals.verification.verificationStep === "VERIFY_NEW_PHONE_NUMBER") {
        this.modals.verification.verificationType = "NEW_PHONE_NUMBER"
      }

      if (!this.modals.verification.visibleModal) {
        this.modals.verification.visibleModal = true
      }

      await this.resendCode()
    },

    hideVerificationModal() {
      this.modals.verification.verificationStep = ""
      this.modals.verification.verificationType = ""
      this.modals.verification.visibleModal = false
    },

    verify() {
      const json = JSON.stringify({
        "code": this.modals.verification.verificationCode,
      });

      axios.post(`/user/verification/verify`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.submitted = false;

        const nextStep = result.data.nextStep;
        if (!nextStep) {
          return
        }

        if (nextStep !== 'NONE') {
          this.resendCode()

          if (nextStep === 'VERIFY_NEW_EMAIL') {
            this.openVerificationModal(nextStep)
            Swal.fire("", "Zweryfikuj nowy adres E-mail", "info");
          } else if (nextStep === 'VERIFY_NEW_PHONE_NUMBER') {
            this.openVerificationModal(nextStep)
            Swal.fire("", "Zweryfikuj nowy numer telefonu", "info");
          }
        } else {
          this.hideVerificationModal()
          this.loadForm()
          Swal.fire("", this.$t("verify.success"), "success");
        }
      }).catch((error) => {
        this.submitted = false;
        this.modals.verification.verificationCode = "";
        errorCatcher.catchErrors(error)
      })
    },

    sendProfilePicture(input) {
      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {dataUrl, extension} = result
        this.form.extension = extension
        this.$refs.ecatCropper.openModal(dataUrl, "circle-stencil")
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    },

    successCropImage({ dataUrl, file }) {
      this.form.profilePictureUrl = dataUrl;
      this.form.newProfilePicture = dataUrl;
      this.form.pictureFile = file
    },

    isPossibleNumber() {
      if (!this.form.dialCode || !this.form.phoneNumber) {
        return false
      }

      const phoneNumber = parsePhoneNumber(this.form.dialCode + this.form.phoneNumber)
      if (!phoneNumber) {
        return false
      }

      return phoneNumber.isValid()
    },

    resetProfilePicture() {
      this.form.profilePictureUrl = "https://manager.ec-at.com/assets/images/portrait/small/avatar-s-11.jpg"
      this.form.newProfilePicture = "https://manager.ec-at.com/assets/images/portrait/small/avatar-s-11.jpg"
    },

    async loadForm() {
      this.form = Object.assign({}, useUserStore().getUser);
    },

    resendCode() {
      const currentTime = new Date().getTime();
      if (this.modals.verification.verificationType === "NEW_PHONE_NUMBER" && this.$store.getters["lastclick/getP"]) {
        if (this.$store.getters["lastclick/getP"] > currentTime) {
          Swal.fire(this.$t('message.error'), "Następny raz kod możesz wysłać za: " + timeUtil.toHumanReadableTime(this.$store.getters["lastclick/getP"] - currentTime), "error");
          return
        }
      } else if (this.modals.verification.verificationType === "NEW_EMAIL" && this.$store.getters["lastclick/getE"]) {
        if (this.$store.getters["lastclick/getE"] > currentTime) {
          Swal.fire(this.$t('message.error'), "Następny raz kod możesz wysłać za: " + timeUtil.toHumanReadableTime(this.$store.getters["lastclick/getE"] - currentTime), "error");
          return
        }
      }

      const json = JSON.stringify({
        type: this.modals.verification.verificationType,
        environmentType: process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE
      });

      axios.post(`/user/verification/send`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(async () => {
        this.$bvToast.toast(this.$t("verify.sent-code"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        const time = new Date(new Date().getTime() + (14 * 60 * 1000)).getTime()
        if (this.status === "NEW_PHONE_NUMBER") {
          await this.$store.dispatch("lastclick/setP", time)
        } else if (this.status === "NEW_EMAIL") {
          await this.$store.dispatch("lastclick/setE", time)
        }

        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    getAllCountries() {
      return allCountries;
    }

  },

  components: {
    Enable2faModal,
    Layout,
    PageHeader,
    ecatCropper
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        dialCode: "",
        phoneNumber: "",
        username: "",
        newProfilePicture: "",
        pictureFile: null,
        extension: ""
      },

      modals: {
        verification: {

          visibleModal: false,
          verificationStep: "",
          verificationType: "",
          verificationCode: ""

        }
      },

      submitted: false,
    }
  },

  validations: {
    form: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      dialCode: {required},
      phoneNumber: {required},
      username: { required }
    }
  },

  setup() {
    const userStore = useUserStore();
    return {
      user: computed(() => userStore.getUser)
    }
  },

  async created() {
    await this.loadForm()
  }
}

</script>

<template>
  <Layout>
    <PageHeader :title="$t('navbar.dropdown.profile')" :items="getItems()" />

    <enable-2fa-modal ref="enable2FARef" @enabled-2fa="loadForm" />
    <ecat-cropper ref="ecatCropper" @success="successCropImage" />

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="media mb-4">
                  <img class="avatar-lg align-self-start rounded mr-3" :src="form.profilePictureUrl" alt/>
                  <div class="media-body">
                    <h5 class="mt-0 font-size-14">{{ $t('profile.picture') }}</h5>
                    <div class="button-items">
                      <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="send-profile-picture" @input="sendProfilePicture" hidden/>
                      <label for="send-profile-picture" class="btn btn-primary">{{ $t('message.send') }}</label>
                      <b-button @click="resetProfilePicture" variant="secondary">{{  $t('message.reset') }}</b-button>
                    </div>

                    <p class="mb-0 font-size-12">{{ $t('profile.allowed-file-types')}} </p>
                  </div>
                </div>

                <p>Logowanie 2FA: <b :class="form.twoFactorAuthEnabled ? 'text-success' : 'text-danger'">{{ form.twoFactorAuthEnabled ? "włączone" : "wyłączone" }}</b>
                  <b-button v-if="form.twoFactorAuthEnabled" @click="disable2FA" variant="danger" class="mx-2 btn-sm">Wyłącz 2FA</b-button>
                  <b-button v-else @click="$refs.enable2FARef.openModal()" variant="success" class="mx-2 btn-sm">Włącz 2FA</b-button>
                </p>

                <form class="needs-validation">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="firstName">Nazwa użytkownika</label>
                        <input
                            id="firstName"
                            v-model="form.username"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': this.submitted && $v.form.username.$error }"/>
                        <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.username.required">{{ $t('message.required')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <form class="needs-validation">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="firstName">{{  $t('message.first-name') }}</label>
                        <input
                            id="firstName"
                            v-model="form.firstName"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': this.submitted && $v.form.firstName.$error }"/>
                        <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.firstName.required">{{ $t('message.required')}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lastName">{{ $t('message.last-name') }}</label>
                        <input
                            id="lastName"
                            v-model="form.lastName"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && $v.form.lastName.$error }"/>
                        <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.lastName.required">{{ $t('message.required')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <form class="needs-validation py-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input
                            id="email"
                            v-model="form.email"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': this.submitted && $v.form.email.$error }"/>
                        <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                          <span v-if="!$v.form.email.required">{{ $t('message.required')}}</span>
                          <span v-if="!$v.form.email.email">{{ $t('message.required')}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{  $t('message.country') }}</label>
                        <select v-model="form.dialCode" class="custom-select" :class="{ 'is-invalid': submitted && $v.form.dialCode.$error }">
                          <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.dialCode}`" >{{ item.name }} ({{ item.dialCode }})</option>
                        </select>

                        <div v-if="submitted && $v.form.dialCode.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dialCode.required">{{ $t('message.required')}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ $t('message.phone-number') }}</label>
                        <input
                            id="phoneNumber"
                            v-model="form.phoneNumber"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': (submitted && $v.form.phoneNumber.$error) || !isPossibleNumber()}"/>
                        <div v-if="submitted && $v.form.phoneNumber.$error" class="invalid-feedback">
                          <span v-if="!$v.form.phoneNumber.required">{{ $t('message.required')}}</span>
                        </div>

                        <div v-if="!isPossibleNumber()" class="invalid-feedback">{{ $t('message.number-error')}}</div>
                      </div>
                    </div>
                  </div>

                  <b-button @click="save" variant="primary" class="w-sm ml-1">{{  $t('message.save') }}</b-button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-2"
        v-model="modals.verification.visibleModal"
        hide-footer
        :title="`Weryfikacja ${modals.verification.verificationStep === 'VERIFY_NEW_EMAIL' ? 'E-mail' : 'Numeru telefonu'}`"
        title-class="font-18"
        @esc="modals.verification.visibleModal = false"
        @hide="modals.verification.visibleModal = false">

      <div class="form-group">
        <label>Kod weryfikacyjny</label>
        <input type="number" class="form-control" v-model="modals.verification.verificationCode" />
      </div>

      <div class="text-center">
        <b-button variant="success" @click="verify">Weryfikuj</b-button>
        <b-button class="ml-1" variant="secondary" @click="resendCode">Wyślij kod ponownie</b-button>
      </div>
    </b-modal>
  </Layout>
</template>